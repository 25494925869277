import addToMailchimp from "gatsby-plugin-mailchimp"
import React from "react"
import styled from "@emotion/styled"
import { navigate } from "@reach/router"  


export default class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", fname: "", lname: "", result: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email,  {FNAME: this.state.fname, LNAME: this.state.lname })
    if (result.result === 'error') {
      document.getElementById('helpMessage').style.display = 'block';
    } else {
      navigate(`/success`)
    }
    this.setState({ result: result })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleFNameChange = (event) => {
    this.setState({ fname: event.target.value })
  }

  handleLNameChange = (event) => {
    this.setState({ lname: event.target.value })
  }
render() {
  return (
      <JoinTextContainer>
      <JoinForm onSubmit={this._handleSubmit}>
        <input
          label="Name"
          type="name"
          name="name"
          autoComplete="name"
          placeholder="First Name"
          onChange={this.handleFNameChange}
        />
        <input
          label="Name"
          type="name"
          name="name"
          autoComplete="name"
          placeholder="Last Name"
          onChange={this.handleLNameChange}
        />
        <input
          label="Email"
          type="email"
          name="email"
          autoComplete="email"
          placeholder="Email"
          onChange={this.handleEmailChange}
        />
        <input
          label="Submit"
          type="submit"
        />
        <h3 id="helpMessage"  style={{
            display: `none`, backgroundColor: `white`, color: `black`, padding: `1rem`, fontWeight: `bold`, border: `1px solid black`}}>Looks like you've already subscribed!</h3>
      </JoinForm>
      </JoinTextContainer>
    )
  }
}

const JoinTextContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
`

const JoinForm = styled.form`
  width: 300px;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
`